import { ItemGrade, ProductCategory } from '@hofy/api-shared';
import { DateString, UUID, zeroUuid } from '@hofy/global';
import { nowISODate } from '@hofy/helpers';

export interface ReceiveBYODOrderItemFormData {
    byodOrderItemId: UUID;
    category?: ProductCategory;
    items: ReceiveBYODOrderItemDetailFormData[];
}

export interface ReceiveBYODOrderItemValidData {
    byodOrderItemId: UUID;
    category: ProductCategory;
    items: ReceiveBYODItemFormData[];
}

export interface ReceiveBYODItemFormData {
    itemCode: string;
    serialNumber: string;
    receivedOn: DateString;
    itemGrade: ItemGrade;
}

export interface ReceiveBYODOrderItemDetailFormData {
    itemCode: string | null;
    serialNumber: string | null;
    receivedOn: DateString;
    itemGrade: ItemGrade;
}

export const emptyReceiveBYODOrderItemDetail: ReceiveBYODOrderItemDetailFormData = {
    itemCode: null,
    serialNumber: null,
    receivedOn: nowISODate(),
    itemGrade: ItemGrade.New,
};
export const emptyReceiveBYODOrderItem = (category?: ProductCategory): ReceiveBYODOrderItemFormData => {
    return {
        category,
        byodOrderItemId: zeroUuid,
        items: [],
    };
};
