import React, { FC } from 'react';

import { RepairDetailsDto } from '@hofy/api-admin';
import { formatVariant } from '@hofy/api-shared';
import { Box, FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { ProductItem } from '../../../../components/domain/products/ProductItem';
import { ContractLinkLabel } from '../../../contractsPage/ContractLink';
import { ItemLocationCell } from '../../../itemsPage/components/ItemLocationCell';
import { LabeledItemLink } from '../../../itemsPage/ItemDetailsLink';
import { LabeledOrganizationLink } from '../../../organizationsPage/OrganizationDetailsLink';
import { LabeledUserLink } from '../../../usersPage/UserLink';

interface RepairDetailsTabProps {
    repair: RepairDetailsDto;
}

export const RepairDetailsTab: FC<RepairDetailsTabProps> = ({ repair }) => {
    return (
        <Box column gap={40}>
            <RepairDetailsSection repair={repair} />
            <RepairItemDetailsSection repair={repair} />
            <AuditSection model={repair} />
        </Box>
    );
};

const RepairDetailsSection: FC<RepairDetailsTabProps> = ({ repair }) => {
    return (
        <FormSection label='Repair details'>
            <FormGridRow columns={4}>
                <LabeledText label='Id' content={`${repair.id}`} />
                <LabeledText label='Public Id' content={`${repair.publicId}`} />
                {repair.user && (
                    <>
                        <LabeledUserLink label='Requested by' user={repair.user} />
                        {repair.organization && (
                            <LabeledOrganizationLink organization={repair.organization} />
                        )}
                        <LabeledText
                            label='Subcontract Id'
                            content={<ContractLinkLabel id={repair.contract} />}
                        />
                    </>
                )}
                {!repair.user && repair.warehouse && (
                    <LabeledText label='Requested by' content={`${repair.warehouse.name}`} />
                )}
            </FormGridRow>
        </FormSection>
    );
};

const RepairItemDetailsSection: FC<RepairDetailsTabProps> = ({ repair }) => {
    return (
        <FormSection label='Item details'>
            <Box row wrap gap={40} alignItems='flex-start'>
                <LabeledItemLink item={repair.item} />
                <Box flex={2}>
                    <ProductItem
                        image={repair.item.variant.image}
                        label={repair.item.product.name}
                        subLabel={formatVariant(repair.item.variant)}
                    />
                </Box>
                <Box flex={1}>
                    <ItemLocationCell location={repair.item.location} />
                </Box>
            </Box>
        </FormSection>
    );
};
