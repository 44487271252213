import React from 'react';

import {
    belongsToHofy,
    belongsToOrganization,
    formatUserName,
    ItemLocation,
    LocationDto,
    useTrItemLocation,
} from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

import { UserLink } from '../../usersPage/UserLink';

interface ItemLocationCellProps {
    location: LocationDto;
}

export const ItemLocationCell = ({ location: { location, warehouse, user } }: ItemLocationCellProps) => {
    const trItemLocation = useTrItemLocation();
    const icon = locationIcon[location];
    switch (location) {
        case ItemLocation.AtWarehouse:
            return (
                <Box row gap={8}>
                    <Icon size={16} svg={icon} color={Color.ContentSecondary} />
                    <Box>
                        {warehouse?.bin ? (
                            <Paragraph3 color={Color.ContentSecondary}>{warehouse.bin.identifier}</Paragraph3>
                        ) : (
                            <Paragraph3 color={Color.ContentSecondary}>
                                {belongsToOrganization(warehouse?.ownership)
                                    ? 'At organization storage location'
                                    : trItemLocation(location)}
                            </Paragraph3>
                        )}
                        {warehouse && belongsToHofy(warehouse.ownership) && (
                            <Paragraph3 color={Color.ContentSecondary}>{warehouse.name}</Paragraph3>
                        )}
                    </Box>
                </Box>
            );

        case ItemLocation.WithUser:
            return (
                <Box row gap={8}>
                    <Icon size={16} svg={icon} color={Color.ContentSecondary} />
                    <Box>
                        <Paragraph3>{trItemLocation(location)}</Paragraph3>
                        {user && (
                            <UserLink id={user.id} color={Color.ContentSecondary}>
                                <Paragraph3 color={Color.ContentSecondary}>{formatUserName(user)}</Paragraph3>
                            </UserLink>
                        )}
                    </Box>
                </Box>
            );
        case ItemLocation.WriteOff:
        case ItemLocation.WithCourier:
            return (
                <Box row gap={8}>
                    <Icon size={16} svg={icon} color={Color.ContentSecondary} />
                    <Paragraph3>{trItemLocation(location)}</Paragraph3>
                </Box>
            );
    }
};

const locationIcon: Record<ItemLocation, Svg> = {
    [ItemLocation.AtWarehouse]: SvgIcon.Home,
    [ItemLocation.WithCourier]: SvgIcon.Truck,
    [ItemLocation.WithUser]: SvgIcon.User,
    [ItemLocation.WriteOff]: SvgIcon.Tool,
};
