import React, { FC } from 'react';

import { allItemGrades, isDeviceCategory, ProductCategory } from '@hofy/api-shared';
import { nowDate, toISODate } from '@hofy/helpers';
import {
    Box,
    FormDateInput,
    FormFieldRecord,
    FormInput,
    FormSelect,
    IconButton,
    InnerCard,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { ReceiveBYODOrderItemDetailFormData } from '../../../../../store/byodOrders/types/ReceiveBYODOrderItemFormData';
import { useTrItemGrade } from '../../../../../store/items/useTrItemGrade';

interface BYODOrderItemDetailsProps {
    itemForm: FormFieldRecord<ReceiveBYODOrderItemDetailFormData>;
    index: number;
    category: ProductCategory;
    deleteItem(index: number): void;
}

export const BYODOrderItemDetails: FC<BYODOrderItemDetailsProps> = ({
    itemForm,
    index,
    category,
    deleteItem,
}) => {
    const trItemGrade = useTrItemGrade();

    return (
        <InnerCard padding={20} row fullWidth justify='space-between'>
            <Box fullHeight justify='flex-start'>
                <Paragraph3>{index + 1}.</Paragraph3>
            </Box>
            <FormInput
                label='Item code'
                api={itemForm.itemCode}
                autoFocus={index === 0}
                isRequired
                nullable
            />
            <FormInput
                label='Serial number'
                api={itemForm.serialNumber}
                isRequired={isDeviceCategory(category)}
                nullable
            />
            <FormDateInput
                label='Date received'
                api={itemForm.receivedOn}
                maxDate={toISODate(nowDate())}
                isRequired
            />
            <FormSelect
                options={allItemGrades}
                toLabel={trItemGrade}
                toText={trItemGrade}
                label='Condition'
                api={itemForm.itemGrade}
                width={150}
                isRequired
                nullable={false}
            />
            <IconButton icon={SvgIcon.Cross} onClick={() => deleteItem(index)} />
        </InnerCard>
    );
};
