import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Icon, LabeledLink, SvgIcon } from '@hofy/ui';

import { getUserLink } from '../../components/routing/adminLinks';
import { Link } from '../../components/routing/Link';

interface UserLinkProps {
    id: UUID;
    color?: Color;
    children?: ReactNode;
}

export const UserLink: FC<UserLinkProps> = ({ id, color, children }) => {
    return (
        <Link flex color={color} to={getUserLink(id)}>
            {children}
        </Link>
    );
};

interface UserDetailsLinkProps {
    user: {
        id: UUID;
        firstName: string;
        lastName: string;
    };
}

export const UserDetailsLink: FC<UserDetailsLinkProps> = ({ user }) => {
    return (
        <UserLink id={user.id}>
            <Icon size={14} svg={SvgIcon.User} marginRight={4} />
            {user.firstName} {user.lastName}
        </UserLink>
    );
};

interface LabeledUserLinkProps {
    user: {
        id: UUID;
        firstName: string;
        lastName: string;
    } | null;
    label?: string;
}

export const LabeledUserLink: FC<LabeledUserLinkProps> = ({ label = 'User', user }) => {
    return <LabeledLink label={label} content={user ? <UserDetailsLink user={user} /> : '--'} />;
};
