import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { useBillingEntityQuery } from '@hofy/api-admin';
import {
    AddressType,
    BillingEntityPaymentTerms,
    emptyAddressPayload,
    useTrPaymentTerms,
} from '@hofy/api-shared';
import { CountryLabel } from '@hofy/core';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import {
    Badge,
    BooleanBadge,
    Box,
    FormContainer,
    FormGridRow,
    FormSection,
    Labeled,
    LabeledText,
    Link,
    Placeholder,
    Skeleton,
    SvgIllustration,
} from '@hofy/ui';

import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { BillingEntityStatusChip } from '../../../../components/domain/billingEntity/BillingEntityStatusChip';
import { NetsuiteStatusChip } from '../../../../components/domain/netsuite/NetsuiteStatusChip';
import { AdminNavLink } from '../../../../components/routing/AdminNavLink';
import { useAdminI18n } from '../../../../i18n/useAdminI18n';
import { AdminInvoicingTab } from '../../../../store/invoicing/types/AdminInvoicingTab';
import { LabeledOrganizationLink } from '../../../organizationsPage/OrganizationDetailsLink';
import { ContactCell } from '../components/ContactCell';
import { TwoCreditDecisionChip } from '../components/TwoCreditChip';
import { TwoStatusChip } from '../components/TwoStatusChip';

interface BillingEntityDetailsProps {
    billingEntityId: UUID;
}

export const BillingEntityDetails: FC<BillingEntityDetailsProps> = ({ billingEntityId }) => {
    const { tr } = useAdminI18n();
    const trPaymentTerms = useTrPaymentTerms<BillingEntityPaymentTerms>(BillingEntityPaymentTerms);
    const { billingEntity, isLoading } = useBillingEntityQuery(billingEntityId);
    const { formatPrice } = usePrice();

    if (isLoading) {
        return <Skeleton />;
    }

    if (billingEntity === undefined) {
        return (
            <Placeholder
                illustration={SvgIllustration.FinanceSearch}
                title={tr('billing-entity-page.empty-state.title')}
                message={tr('billing-entity-page.empty-state.subtitle')}
            />
        );
    }

    return (
        <Box overflow='auto' fullHeight row alignItems='flex-start' paddingHorizontal={40}>
            <FormContainer flex={1} marginVertical={30} marginRight={20}>
                <FormSection label='Details'>
                    <FormGridRow columns={4}>
                        <LabeledText label='Name' content={billingEntity.name} />
                        <LabeledText label='Id' content={billingEntity.id} />
                        <LabeledText label='Public Id' content={billingEntity.publicId} />
                        <Labeled
                            label='Status'
                            content={<BillingEntityStatusChip status={billingEntity.status} />}
                        />
                        <LabeledText
                            label='Registered company number'
                            content={billingEntity.registeredCompanyNumber}
                        />
                        <LabeledText label='VAT Number / Tax ID' content={billingEntity.vatNumber} />
                        <LabeledText label='Currency' content={billingEntity.currency} />
                        <LabeledText
                            label='Payment Terms'
                            content={trPaymentTerms(billingEntity.paymentTerms)}
                        />
                        <Labeled
                            label='Located in'
                            content={<CountryLabel country={billingEntity.billingAddress?.country} />}
                        />
                        <Labeled
                            label='Netsuite'
                            content={<NetsuiteStatusChip status={billingEntity.netsuite} />}
                        />
                        <Labeled
                            label='Confirmed by manager'
                            content={
                                <Badge
                                    label={`${
                                        billingEntity.isConfirmedByManager ? 'Confirmed' : 'Not Confirmed'
                                    }`}
                                    color={billingEntity.isConfirmedByManager ? 'green' : 'orange'}
                                />
                            }
                        />
                        <LabeledText
                            label='Billing address'
                            content={
                                <PostAddressView
                                    address={
                                        billingEntity.billingAddress ||
                                        emptyAddressPayload(AddressType.Billing)
                                    }
                                />
                            }
                        />
                        {billingEntity.contacts.map(contact => (
                            <Labeled
                                key={contact.id}
                                label='Contact'
                                content={<ContactCell contact={contact} />}
                            />
                        ))}
                    </FormGridRow>
                </FormSection>
                <FormSection label='Organization'>
                    <FormGridRow columns={4}>
                        {billingEntity.organization && (
                            <LabeledOrganizationLink organization={billingEntity.organization} />
                        )}
                        <Labeled
                            label='Is default'
                            content={<BooleanBadge value={billingEntity.isDefault} offColor='grey' />}
                        />
                        <Labeled
                            label='Contract signed'
                            content={<BooleanBadge value={billingEntity.isContractSigned} offColor='grey' />}
                        />
                    </FormGridRow>
                </FormSection>
                <FormSection label='Deel'>
                    <FormGridRow columns={4}>
                        <LabeledText label='Id' content={billingEntity.deel.id} />
                        <LabeledText label='Last synced at' content={billingEntity.deel.lastUpdatedAt} />
                    </FormGridRow>
                </FormSection>
                <FormSection label='Two'>
                    <FormGridRow columns={4}>
                        <Labeled
                            label='Two integration (API)'
                            content={<BooleanBadge value={billingEntity.twoApiEnabled} offColor='grey' />}
                        />
                        <Labeled
                            label='Two integration (Spreadsheet)'
                            content={
                                <BooleanBadge value={billingEntity.twoSpreadsheetEnabled} offColor='grey' />
                            }
                        />
                        {billingEntity.twoApiEnabled && (
                            <Labeled
                                label='Status'
                                content={<TwoStatusChip billingEntity={billingEntity} />}
                            />
                        )}
                        {billingEntity.twoApiEnabled && (
                            <LabeledText
                                label='Hofy set recourse limit (per sub.)'
                                content={formatPrice(billingEntity.twoRecourseLimit)}
                            />
                        )}
                        {billingEntity.twoCredit && (
                            <>
                                <Labeled
                                    label='Credit decision'
                                    content={
                                        <TwoCreditDecisionChip
                                            creditDecision={billingEntity.twoCredit.creditDecision}
                                        />
                                    }
                                />
                                <LabeledText
                                    label='Checked at'
                                    content={formatDateTime(billingEntity.twoCredit.creditCheckedAt)}
                                />
                                <LabeledText
                                    label='Available monthly credit'
                                    content={`${billingEntity.twoCredit.creditMonths} x ${formatPrice(
                                        billingEntity.twoCredit.monthlyCredit,
                                    )}`}
                                />
                            </>
                        )}
                        <Labeled
                            label='Two recoursed-back'
                            content={
                                <BooleanBadge
                                    value={billingEntity.twoRecoursedBack}
                                    offColor='grey'
                                    onColor='red'
                                />
                            }
                        />
                        <Labeled
                            label='Repayment plans'
                            content={
                                <Link
                                    to={`${AdminNavLink.Invoicing}/${AdminInvoicingTab.RepaymentPlans}?billingEntities=${billingEntityId}`}
                                >
                                    View all
                                </Link>
                            }
                        />
                    </FormGridRow>
                </FormSection>
                <AuditSection model={billingEntity} />
            </FormContainer>
        </Box>
    );
};
