import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import { SignInApp } from '@hofy/api-auth';
import { RWDProvider } from '@hofy/ui';

import { AuthNavLink } from './AuthNavLink';
import { ForgotPasswordPage } from './forgotPasswordPage/ForgotPasswordPage';
import { SetPasswordPage } from './setPasswordPage/SetPasswordPage';
import { SignInPage } from './signInPage/SignInPage';
import { SignUpPage } from './signUpPage/SignUpPage';

interface AuthRouterProps {
    app: SignInApp;
}

export const AuthRouter: FC<AuthRouterProps> = ({ app }) => {
    return (
        <RWDProvider enabled>
            <Route path={AuthNavLink.SignIn} render={() => <SignInPage app={app} />} />
            <Route path={AuthNavLink.SignUp} component={SignUpPage} />
            <Route path={AuthNavLink.ForgotPassword} component={ForgotPasswordPage} exact />
            <Route path={AuthNavLink.SetPassword} component={SetPasswordPage} exact />
        </RWDProvider>
    );
};
