import React, { FC } from 'react';

import { SignInApp } from '@hofy/api-auth';

import { useSignIn } from '../../store/auth/useSignIn';
import { SignInForm } from './SignInForm';

export const SignInPage: FC<{ app: SignInApp }> = ({ app }) => {
    const { signInForm, signInError, signInWithPublicSSO, signInIsLoading, brokeringIsLoading, authStage } =
        useSignIn();

    return (
        <SignInForm
            authStage={authStage}
            form={signInForm}
            authError={signInError}
            signInWithPublicSSO={sso => signInWithPublicSSO(sso, app, null)}
            isLoading={signInIsLoading || brokeringIsLoading}
        />
    );
};
