import React, { FC, useState } from 'react';

import { AssignmentDetailsDto, ListProductsOptions, useAssignmentQuery } from '@hofy/api-admin';
import { Slideout, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import {
    AsyncButton,
    Box,
    Button,
    ErrorStatePlaceholder,
    isRequired,
    Paragraph3,
    RequiredKeys,
    SearchInput,
    SectionTitle3,
    Skeleton,
    SvgIcon,
    useForm,
    validator,
} from '@hofy/ui';

import { ProductOverview } from '../../../components/domain/products/ProductOverview';
import {
    ProductAndVariant,
    VariantPicker,
} from '../../../components/domain/products/productPicker/VariantPicker';
import { useCreateConsumable } from '../../../store/assignments/useCreateConsumable';
import { usePickCustomProduct } from '../../../store/assignments/usePickCustomProduct';
import { useSubstituteProduct } from '../../../store/assignments/useSubstituteProduct';
import { AssignmentLink } from '../../assignmentsPage/AssignmentLink';

interface VariantPickerFormData {
    productAndVariant: ProductAndVariant | null;
}

enum VariantPickerSlideoutTypes {
    CreateConsumable = 'create-consumable',
    PickCustomProduct = 'pick-custom-product',
    SubstituteProduct = 'substitute-product',
}

interface VariantPickerSlideoutTitles {
    slideoutTitle: string;
    assignmentSectionTitle: string;
    variantSectionTitle: string;
    submitButtonLabel: string;
}

const variantPickerSlideoutTitles: Record<VariantPickerSlideoutTypes, VariantPickerSlideoutTitles> = {
    [VariantPickerSlideoutTypes.CreateConsumable]: {
        slideoutTitle: 'Send consumable item',
        assignmentSectionTitle: 'Linked assignment',
        variantSectionTitle: 'Possible consumables',
        submitButtonLabel: 'Create new assignment',
    },
    [VariantPickerSlideoutTypes.PickCustomProduct]: {
        slideoutTitle: 'Pick custom product',
        assignmentSectionTitle: 'Requested product',
        variantSectionTitle: 'Possible products',
        submitButtonLabel: 'Pick product',
    },
    [VariantPickerSlideoutTypes.SubstituteProduct]: {
        slideoutTitle: 'Substitute product',
        assignmentSectionTitle: 'Requested product',
        variantSectionTitle: 'Possible substitutes',
        submitButtonLabel: 'Substitute product',
    },
};

interface VariantPickerSlideoutProps {
    type: VariantPickerSlideoutTypes;

    slideoutId: string;
    assignmentId: UUID;
    isSubmitLoading: boolean;
    isSubmitError: boolean;

    onSubmit(variant: ProductAndVariant): void;
    onClose(): void;
}

const VariantPickerSlideout: FC<VariantPickerSlideoutProps> = ({
    type,
    slideoutId,
    assignmentId,
    isSubmitLoading,
    isSubmitError,
    onSubmit,
    onClose,
}) => {
    const [search, setSearch] = useState<string>('');

    const {
        data: assignment,
        isLoading: isLoadingAssignment,
        isError: isAssignmentError,
    } = useAssignmentQuery(assignmentId);

    const form = useForm<VariantPickerFormData, RequiredKeys<VariantPickerFormData>>({
        initial: {
            productAndVariant: null,
        },
        validate: validator<VariantPickerFormData>({
            productAndVariant: isRequired('Product and variant must be selected'),
        }),
        onSubmit: values => {
            onSubmit(values.productAndVariant);
        },
    });

    const { slideoutTitle, assignmentSectionTitle, variantSectionTitle, submitButtonLabel } =
        variantPickerSlideoutTitles[type];

    const content = () => {
        if (isLoadingAssignment) {
            return <Skeleton fullWidth />;
        }

        if (isAssignmentError || !assignment) {
            return <ErrorStatePlaceholder />;
        }

        const product = assignment.product;
        return (
            <>
                <Box
                    borderBottom
                    column
                    paddingHorizontal='mainMarginHorizontal'
                    paddingVertical={20}
                    gap={20}
                >
                    <SectionTitle3>{assignmentSectionTitle}</SectionTitle3>
                    <Box row gap={20}>
                        <Paragraph3>
                            <AssignmentLink id={assignmentId}>#{assignmentId}</AssignmentLink>
                        </Paragraph3>
                        <ProductOverview imageSize={60} product={product} images={product.image} />
                    </Box>
                </Box>
                <Box borderBottom column paddingVertical={20} gap={20} flex='auto'>
                    <SectionTitle3 paddingHorizontal='mainMarginHorizontal'>
                        {variantSectionTitle}
                    </SectionTitle3>
                    <VariantPicker
                        onPick={productAndVariant => {
                            form.setValues({ productAndVariant });
                        }}
                        selected={form.values.productAndVariant}
                        listProductsOptions={getListProductOptions(type, assignment, search)}
                    />
                </Box>
            </>
        );
    };

    return (
        <Slideout width={600} onClose={onClose} slideoutId={slideoutId}>
            <SlideoutHeader title={slideoutTitle} justify='space-between' paddingRight={20}>
                <SearchInput value={search} onChange={setSearch} placeholder='Search product, SKU, …' />
            </SlideoutHeader>
            {content()}
            <SlideoutFooter>
                <Button
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                    label='Cancel'
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton
                    label={submitButtonLabel}
                    isError={isSubmitError}
                    isLoading={isSubmitLoading}
                    onClick={form.submit}
                    disabled={!form.values.productAndVariant}
                />
            </SlideoutFooter>
        </Slideout>
    );
};

const getListProductOptions = (
    type: VariantPickerSlideoutTypes,
    assignment: AssignmentDetailsDto,
    search: string,
): Partial<ListProductsOptions> => {
    switch (type) {
        case VariantPickerSlideoutTypes.CreateConsumable:
            return {
                organizationIds: [assignment.organization.id],
                isInternal: true,
                search,
            };
        case VariantPickerSlideoutTypes.PickCustomProduct:
        case VariantPickerSlideoutTypes.SubstituteProduct:
            return {
                organizationIds: [assignment.organization.id],
                categories: [assignment.product.category],
                search,
            };
    }
};

interface BaseVariantPickerSlideoutProps {
    assignmentId: UUID;
    onClose(): void;
}

export const CreateConsumableSlideout: FC<BaseVariantPickerSlideoutProps> = ({ assignmentId, onClose }) => {
    const { createConsumable, isPending, isError } = useCreateConsumable(assignmentId, onClose);
    return (
        <VariantPickerSlideout
            type={VariantPickerSlideoutTypes.CreateConsumable}
            slideoutId='create-consumable'
            assignmentId={assignmentId}
            isSubmitLoading={isPending}
            isSubmitError={isError}
            onSubmit={selected => createConsumable({ variantId: selected.variant.id })}
            onClose={onClose}
        />
    );
};

export const PickCustomProductSlideout: FC<BaseVariantPickerSlideoutProps> = ({ assignmentId, onClose }) => {
    const { updateCustomVariant, isPending, isError } = usePickCustomProduct(assignmentId, onClose);
    return (
        <VariantPickerSlideout
            type={VariantPickerSlideoutTypes.PickCustomProduct}
            slideoutId='pick-custom-product'
            assignmentId={assignmentId}
            isSubmitLoading={isPending}
            isSubmitError={isError}
            onSubmit={selected => updateCustomVariant({ customVariantId: selected.variant.id })}
            onClose={onClose}
        />
    );
};

export const SubstituteProductSlideout: FC<BaseVariantPickerSlideoutProps> = ({ assignmentId, onClose }) => {
    const { substitute, isPending, isError } = useSubstituteProduct(assignmentId, onClose);
    return (
        <VariantPickerSlideout
            type={VariantPickerSlideoutTypes.SubstituteProduct}
            slideoutId='substitute-product'
            assignmentId={assignmentId}
            isSubmitLoading={isPending}
            isSubmitError={isError}
            onSubmit={selected => substitute({ variantId: selected.variant.id })}
            onClose={onClose}
        />
    );
};
